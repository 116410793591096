<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :headers="headers"
      :items="scores"
      :search="search"
      title="Daftar Nilai"
      subtitle="Halaman untuk menambahkan nilai siswa/i"
      add-btn="Tambah Data"
      :total-pages.sync="totalPages"
      @add-item="showFormAdd"
    >
    </DataTablePagination>
  </v-card>
</template>

<script>
import DataTablePagination from '../components/DataTablePagination.vue'

export default {
  name: 'Score',
  components: { DataTablePagination },
  props: {},
  data() {
    return {
      page: 1,
      totalPages: 0,
      totalItems: 0,
      search: '',
      headers: [
        { text: '#', value: '' },
        { text: 'Nama Siswa', value: '' },
        { text: 'Guru', value: '' },
        { text: 'NR', value: '' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
}
</script>

<style>
</style>
